import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepone',
  templateUrl: './dealer-stepone.component.html',
  styleUrls: ['./dealer-stepone.component.scss'],
})
export class DealerSteponeComponent implements OnInit {

  /**
   * 1. are we logged in?
   * 2. if we're logged in, do we have a submission for this year already?
   * 3. are we staff?
   * 4. does our name/email match against any ban lists?
   */

  constructor() { }

  ngOnInit() {}

}
