import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../services/auth.service';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-staff-fetch-details',
  templateUrl: './staff-fetch-details.component.html',
  styleUrls: ['./staff-fetch-details.component.scss'],
})
export class StaffFetchDetailsComponent implements OnInit {

  key: string;

  constructor(private route: ActivatedRoute, private http: HttpClient, private auth: AuthService, private nav: NavigationService) { }

  ngOnInit() {
    this.key = this.route.snapshot.queryParamMap.get("uflsStaffSession");
    this.http.get('https://backend.furrydelphia.org/app/redirect/marketplace/otp/'+this.key+'/')
      .subscribe(
        (res: any) => {
          console.log(res)
          this.auth.activeUser = res;
          this.nav.navigate("/")
        },
        (err) => {
          console.log(err);
        }
      )
  }

}
