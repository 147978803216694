import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavigationService} from '../../services/navigation.service';
import { AlertController } from '@ionic/angular';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    public nav: NavigationService,
    public alertController: AlertController,
    public auth: AuthService
  ) { }

  ngOnInit() {}

  loadThing(url: string) {
    this.nav.navigate(url);
  }
  async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Not Available',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

}
