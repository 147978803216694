import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new',
  templateUrl: './new-artist.component.html',
  styleUrls: ['./new-artist.component.scss'],
})
export class NewArtistComponent implements OnInit {

  slideOpts = { };

  constructor() { }

  ngOnInit() {}

}
