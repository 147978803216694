import {Component, Input, OnInit} from '@angular/core';
import {RestService} from '../../../services/rest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Dealer} from '../../../interfaces/dealer';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-dealer-detail',
  templateUrl: './dealer-detail.component.html',
  styleUrls: ['./dealer-detail.component.scss'],
})
export class DealerDetailComponent implements OnInit {

  constructor(
    public rest: RestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalController: ModalController
  ) { }

  @Input() dealerId: string;
  @Input() isModal: boolean = false;
  dealer: Dealer;
  fullyLoaded: boolean = false;

  dismissModal(){
    this.modalController.dismiss();
  }

  ngOnInit() {
    if(this.dealerId === undefined){
      this.dealerId = this.activatedRoute.snapshot.paramMap.get('id');
    }
    this.rest.get('dealers/'+this.dealerId+'/')
      .subscribe(
        (res) => {
          this.dealer = res;
          this.fullyLoaded = true;
        },
        (err) => {
          this.router.navigate(['/'])
        }
      )
  }

  loadWebsite(website: string) {

  }
}
