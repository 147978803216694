import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {NewDealersComponent} from './pages/dealer/new/new-dealers.component';
import {NewArtistComponent} from './pages/artist/new/new-artist.component';
import {LoginComponent} from './pages/login/login.component';
import {StaffFetchDetailsComponent} from './utils/ufls/staff-fetch-details/staff-fetch-details.component';
import {DealerDetailComponent} from './pages/dealer/dealer-detail/dealer-detail.component';
import {ContactComponent} from './pages/contact/contact.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'artist/new',
    component: NewArtistComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'application/dealer',
    loadChildren: () => import('./application/dealer/dealer.module').then( m => m.DealerPageModule)
  },
  {
    path: 'ufls/staff',
    component: StaffFetchDetailsComponent
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'dealer/:id',
    component: DealerDetailComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'admin-map',
    loadChildren: () => import('./pages/admin-map/admin-map.module').then( m => m.AdminMapPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
