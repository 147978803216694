import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-new',
  templateUrl: './new-dealers.component.html',
  styleUrls: ['./new-dealers.component.scss'],
})
export class NewDealersComponent implements OnInit {

  slideOpts = { };

  constructor(
    private nav: NavigationService
  ) { }

  ngOnInit() {

  }

  beginApplication() {
    this.nav.navigate('/application/dealer');
  }
}
