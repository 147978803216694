import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage-angular';
import {LoginComponent} from './pages/login/login.component';
import {StaffFetchDetailsComponent} from './utils/ufls/staff-fetch-details/staff-fetch-details.component';
import {HomeComponent} from './pages/home/home.component';
import {NewDealersComponent} from './pages/dealer/new/new-dealers.component';
import {NewArtistComponent} from './pages/artist/new/new-artist.component';
import {DealerPageModule} from './application/dealer/dealer.module';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {DealerDetailComponent} from './pages/dealer/dealer-detail/dealer-detail.component';
import {ContactComponent} from './pages/contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    StaffFetchDetailsComponent,
    HomeComponent,
    LoginComponent,
    NewDealersComponent,
    NewArtistComponent,
    DealerDetailComponent,
    ContactComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    RouterModule,
    IonicStorageModule.forRoot(),
    DealerPageModule,
    HttpClientModule,
    LeafletModule,
    LeafletDrawModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
