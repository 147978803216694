import { Component, OnInit } from '@angular/core';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.page.html',
  styleUrls: ['./dealer.page.scss'],
})
export class DealerPage implements OnInit {

  constructor(
    private nav: NavigationService
  ) { }

  ngOnInit() {
    this.nav.navigate("/application/dealer/step1")
  }

}
